<template>
  <div class="h-screen">
    <beta
      v-if="
        $route.name !== 'invitationInscription' && $route.name !== 'invitation'
      "
    ></beta>
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
</template>

<script>
export default {
  created() {
    // //console.log(this.);
  }
};
</script>
